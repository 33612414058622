body {
  margin: 0px;
  overflow: hidden;
}

#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

#fpsLabel {
  position: fixed;
  top: 0px;
  right: 0px;
  color: white;
}

#title {
  position: fixed;
  font-size: 40px;
  width: 500px;
  height: 80px;
  top: 0;
  left: 80%;
  right: 80%;
  color: white;
}

canvas {
  width: 100%;
  height: 100%;
}

img {
  width:auto;
  height:auto;
  max-width:100%;
  max-height:100vh;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 12em;
  height: 3em;
  border-radius: 1em;
  font-size: 2em;
  font-family: sans-serif;
  font-style: bold;
  text-align: center;
  vertical-align: middle;
  line-height: 3em; 
  background: white;
  color: gray;
  opacity: 0;

  animation: fade 1.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes fade {
  0%,100% { opacity: 0; }
  60% { opacity: 1; }
}
